import { PermissionService } from '@Services/permission/permission.service';
import { CommonModule } from '@angular/common';
import { NAVIGATION_TABS, ProjectTabKeys } from './constants';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule, NavigationEnd } from '@angular/router';
import { Subscription, noop } from 'rxjs';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-navigation-tabs',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './navigation-tabs.component.html',
  styleUrl: './navigation-tabs.component.scss',
})
export class NavigationTabsComponent implements OnInit, OnDestroy {
  navigationTabs = NAVIGATION_TABS;

  selectedTab: ProjectTabKeys | null = null;

  private readonly subscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly permissionService: PermissionService,
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.setActiveLink(this.router.url);
    this.subscribeToRouter();
  }

  subscribeToRouter() {
    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd), // Filter only NavigationEnd events
        )
        .subscribe(() => {
          this.setActiveLink(this.router.url);
        }),
    );
  }

  setActiveLink(url: string) {
    // Logic to set active link based on URL
    if (url.includes('/task')) {
      this.selectedTab = ProjectTabKeys.TASKS;
    } else if (url.includes('/claim')) {
      this.selectedTab = ProjectTabKeys.CLAIMS;
    } else if (url.includes('/invoice')) {
      this.selectedTab = ProjectTabKeys.INVOICES;
    } else if (url.includes('/performance')) {
      this.selectedTab = ProjectTabKeys.PERFORMANCE;
    } else if (url.includes('/asset-tree')) {
      this.selectedTab = ProjectTabKeys.ASSET_TREE;
    } else if (url.includes('/offtaker')) {
      this.selectedTab = ProjectTabKeys.OFFTAKER;
    } else if (url.includes('/integration')) {
      this.selectedTab = ProjectTabKeys.INTEGRATION;
    } else {
      this.selectedTab = ProjectTabKeys.GENERAL;
    }
  }

  selectTab(key: ProjectTabKeys) {
    this.selectedTab = key;
  }

  checkVisibilityByPermission(permission: string): boolean {
    return this.permissionService.hasPermission(permission as AllGroupPermissions);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  keyup = noop;
}
