import { Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout/layout.component';
import { inject } from '@angular/core';
import { LocationService } from './services/location/location.service';
import { ProjectListComponent } from './features/project/pages/project-list/project-list.component';
import { ProjectDetailsPageLayoutComponent } from './features/project/pages/project-details-page-layout/project-details-page-layout.component';
import { AuthGuard } from '@shared/guards/auth/auth.guard';
import { PermissionGuard } from '@shared/guards/permission/permission.guard';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./core/auth/auth.routes').then((authRoutes) => authRoutes.routes),
  },
  {
    path: 'error',
    component: LayoutComponent,
    loadChildren: () => import('./core/error/error.routes').then((routes) => routes.errorRoutes),
    data: {
      skipProfileRestCall: true, //do not load new profile data on error pages
    },
  },
  {
    path: '',
    redirectTo: 'project',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'project/create',
        loadComponent: () =>
          import('./features/project/pages/create-project/create-project.component').then(
            (component) => component.CreateProjectComponent,
          ),
        providers: [],
        resolve: {
          regions: () => inject(LocationService).getAllRegions(),
        },
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: AllGroupPermissions.ADD_PROJECT,
        },
      },
      {
        path: 'project',
        component: ProjectListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'project/:id',
        component: ProjectDetailsPageLayoutComponent,
        loadChildren: () =>
          import(
            './features/project/pages/project-details-page-layout/project-details-pages-layout.routes'
          ).then((projectDetailsPageRoutes) => projectDetailsPageRoutes.routes),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
      },
      {
        path: 'tasks',
        loadComponent: () =>
          import('./features/task/pages/global-task-list/global-task-list.component').then(
            (component) => component.GlobalTaskListComponent,
          ),
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: AllGroupPermissions.VIEW_TASK,
        },
      },
      {
        path: 'claims',
        loadComponent: () =>
          import('./features/claim/pages/global-claims-list/global-claims-list.component').then(
            (component) => component.GlobalClaimsListComponent,
          ),
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: AllGroupPermissions.VIEW_CLAIM,
        },
      },
      {
        path: 'invoices',
        loadComponent: () =>
          import('./features/invoice/global-invoice-list/global-invoice-list.component').then(
            (component) => component.GlobalInvoiceListComponent,
          ),
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: AllGroupPermissions.VIEW_INVOICE,
        },
      },
      {
        path: 'user/profile',
        loadComponent: () =>
          import('./features/user/pages/user-profile/user-profile.component').then(
            (component) => component.UserProfileComponent,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'notifications',
        loadComponent: () =>
          import('./features/notifications/notifications/notifications.component').then(
            (component) => component.NotificationsComponent,
          ),
      },
      {
        path: 'users',
        loadComponent() {
          return import('./features/user/pages/users-list/users-list.component').then(
            (component) => component.UsersListComponent,
          );
        },
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: AllGroupPermissions.VIEW_USER,
        },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];
