import {
  ColumnFilterType,
  NumericFilterModeName,
  TextFilterModeName,
} from '@shared/enums/filters/table-filters';
import { DataTableConfig, TableActions, TableColumnDisplay } from '../shared/interfaces/table';
import { ProjectDetailsResponse } from '@shared/interfaces';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { PrimeIcons } from 'primeng/api';
import { DateFilterComponent } from '@shared/components/table/custom-filters/date-filter/date-filter.component';
import { standardFormatDate } from '@shared/utils/common-utils';

export const ProjectsTableConfig: DataTableConfig<ProjectDetailsResponse> = {
  endPoint: 'assetmanagement/projects/',
  showCheckboxes: true,
  sortBy: 'name',
  fixedWidth: true,
  fixedMinWidth: '1720px',
  actionItems: [
    {
      label: 'Edit',
      value: TableActions.openEditMode,
      permission: AllGroupPermissions.CHANGE_PROJECT,
      icon: PrimeIcons.PENCIL,
    },
    {
      label: 'Archive',
      value: TableActions.archive,
      permission: AllGroupPermissions.CAN_ARCHIVE_PROJECT,
      img: 'assets/icons/Archive.svg',
    },
    {
      label: 'Delete',
      value: TableActions.deleteItem,
      permission: AllGroupPermissions.DELETE_PROJECT,
      icon: PrimeIcons.TRASH,
    },
  ],
  additionalParams: [
    {
      key: 'is_archive',
      value: false,
    },
  ],
  formatData: (data: ProjectDetailsResponse[]) => {
    return (data || []).map(
      (item) =>
        ({
          ...item,
          cod_date: standardFormatDate(item.cod_date),
        }) as unknown as ProjectDetailsResponse,
    );
  },
  columns: [
    {
      title: 'Project ID',
      name: 'code',
      displayType: TableColumnDisplay.value,
      width: '9%',
      hasColumnFilters: false,
      sortable: true,
      sortConfig: {
        sortKey: 'code',
      },
    },
    {
      title: 'Site Code',
      name: 'code',
      nestedProperty: 'site',
      displayType: TableColumnDisplay.nestedValue,
      width: '5%',
      hasColumnFilters: false,
      sortable: false,
    },
    {
      title: 'Name',
      name: 'name',
      action: TableActions.viewProjectDetails,
      displayType: TableColumnDisplay.link,
      width: '11%',
      sortable: true,
      hasColumnFilters: false,
      sortConfig: {
        sortKey: 'name',
      },
    },
    {
      title: 'Country',
      name: 'name',
      displayType: TableColumnDisplay.countryFlag,
      nestedProperty: 'country',
      width: '10%',
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'country__name',
      },
      filterConfig: {
        options: Object.values(TextFilterModeName),
        selectedFilter: TextFilterModeName.CONTAINS,
        placeholder: 'Country Name',
        fieldName: 'country__name',
        type: ColumnFilterType.TEXT,
      },
    },
    {
      title: 'City',
      name: 'name',
      displayType: TableColumnDisplay.nestedValue,
      nestedProperty: 'city',
      width: '9%',
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'city__name',
      },
      filterConfig: {
        options: Object.values(TextFilterModeName),
        selectedFilter: TextFilterModeName.CONTAINS,
        placeholder: 'City Name',
        fieldName: 'city__name',
        type: ColumnFilterType.TEXT,
      },
    },
    {
      title: 'Region',
      name: 'name',

      displayType: TableColumnDisplay.nestedValue,
      nestedProperty: 'region',
      hasColumnFilters: true,
      sortable: true,
      width: '9%',
      sortConfig: {
        sortKey: 'region__name',
      },
      filterConfig: {
        options: Object.values(TextFilterModeName),
        selectedFilter: TextFilterModeName.CONTAINS,
        placeholder: 'Region__name',
        fieldName: 'region__name',
        type: ColumnFilterType.TEXT,
        showApplyButton: true,
      },
    },
    {
      title: 'Operational Date',
      name: 'cod_date',
      width: '13%',
      displayType: TableColumnDisplay.value,
      sortable: true,
      hasColumnFilters: true,
      sortConfig: {
        sortKey: 'cod_date',
      },
      filterConfig: {
        options: Object.values(NumericFilterModeName),
        selectedFilter: NumericFilterModeName.EQUALS,
        placeholder: 'mm-dd-yyyy',
        fieldName: 'cod_date',
        type: ColumnFilterType.CUSTOM,
        component: DateFilterComponent as never,
      },
    },
    {
      title: 'Technology',
      name: 'technology',
      width: '12%',
      displayType: TableColumnDisplay.value,
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'technology',
      },
      filterConfig: {
        options: Object.values(TextFilterModeName),
        selectedFilter: TextFilterModeName.CONTAINS,
        placeholder: 'Technology',
        fieldName: 'technology',
        type: ColumnFilterType.TEXT,
        showApplyButton: true,
      },
    },
    {
      title: 'Capacity',
      name: 'capacity_ac',
      width: '10%',
      displayType: TableColumnDisplay.capacity,
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'capacity_ac',
      },
      filterConfig: {
        options: Object.values(NumericFilterModeName),
        selectedFilter: NumericFilterModeName.EQUALS,
        placeholder: 'Capacity AC',
        fieldName: 'capacity_ac',
        type: ColumnFilterType.NUMERIC,
      },
    },
    {
      title: 'Status',
      name: 'status',
      displayType: TableColumnDisplay.status,
      hasColumnFilters: true,
      sortable: true,
      width: '9%',
      sortConfig: {
        sortKey: 'status',
      },
      filterConfig: {
        options: Object.values(TextFilterModeName),
        selectedFilter: TextFilterModeName.CONTAINS,
        placeholder: 'Status',
        fieldName: 'status',
        type: ColumnFilterType.TEXT,
        showApplyButton: true,
      },
    },
    // will be added after user management
    // {
    //   title: 'Created By',
    //   name: 'region',
    //
    //   displayType: TableColumnDisplay.value,
    //   width: '15%',
    // },
  ],
};
